<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>品牌管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="brandList"
      v-bind="otherProps"
      :listCount="brandTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #logoSlot="scope">
        <el-image slot="error" :src="scope.row.brand_logo_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #typeSlot="scope">
        <el-tag v-for="(item, index) in scope.row.info" :key="index">{{ item.type | productTypeFormat }}</el-tag>
      </template>
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>

    <add-edit-brand-drawer :params="addEditInfo" @close="addEditDialogClose"></add-edit-brand-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/brandConfig.js'
import { deletebrand } from '@/api/brand/brand'
import addEditBrandDrawer from './cnps/add-edit-brand-drawer.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      addEditInfo: {
        show: false,
        data: null,
      },
      queryInfo: {
        cur: 1,
        size: 20,
      }
    }
  },
  components: {
    TableUi,
    addEditBrandDrawer
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('brand', ['brandList', 'brandTotal'])
  },
  methods: {
    ...mapActions('brand', ['getBrandList']),
    getDataList () {
      this.getBrandList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
    },
    editClick(row) {
      this.addEditInfo.data = {
        id: row.id,
        brand_name_cn: row.brand_name_cn,
        brand_name_us: row.brand_name_us,
        brand_logo_path: row.brand_logo_path,
        describe_cn: row.describe_cn,
        describe_us: row.describe_us,
        route: row.route,
        info: row.info || []
      }
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deletebrand(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>