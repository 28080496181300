<template>
  <el-drawer :visible.sync="params.show"  :before-close="close" size="30%" :modal="false" :with-header="false" @open="openDrawer">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="品牌logo" prop="brand_logo_path">
        <image-upload v-model="ruleForm.brand_logo_path"></image-upload>
      </el-form-item>
      <el-form-item label="品牌名称(中文)" prop="brand_name_cn">
        <el-input v-model="ruleForm.brand_name_cn"></el-input>
      </el-form-item>
      <el-form-item label="品牌名称(英文)" prop="brand_name_us">
        <el-input v-model="ruleForm.brand_name_us"></el-input>
      </el-form-item>
      <el-form-item label="品牌描述(中文)" prop="describe_cn">
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="ruleForm.describe_cn"></el-input>
      </el-form-item>
      <el-form-item label="品牌描述(英文)" prop="describe_us">
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="ruleForm.describe_us"></el-input>
      </el-form-item>
      <el-form-item label="路由地址" prop="route">
        <el-input v-model="ruleForm.route"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="info">
        <div>
          <el-select v-model="typeSelectValue" placeholder="请选择">
            <el-option
              v-for="item in typeValue"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button @click="addInfoClick">添加</el-button>
        </div>
        <el-tag closable @close="handleClose(index)" v-for="(item, index) in ruleForm.info" :key="index">{{ item.type | productTypeFormat }}</el-tag>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import { editBrand, addBrand } from '@/api/brand/brand'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    imageUpload
  },
  data() {
    return {
      ruleForm: {
        id: null,
        brand_name_cn: '',
        brand_name_us: '',
        brand_logo_path: '',
        describe_cn: '',
        describe_us: '',
        route: '',
        info: []
      },
      typeSelectValue: '',
      typeValue: [
        {
          label: '服务器',
          value: 1
        },
        {
          label: '存储',
          value: 2
        },
        {
          label: '网络传输',
          value: 3
        },
        {
          label: '周边配件',
          value: 4
        }
      ],
      rules: {}
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        brand_name_cn: '',
        brand_name_us: '',
        brand_logo_path: '',
        describe_us: '',
        describe_cn: '',
        route: '',
        info: []
      }
      this.$emit('close')
    },
    handleClose (index) {
      this.ruleForm.info.splice(index, 1)
    },
    openDrawer () {
      if (this.params.data) {
        this.ruleForm = this.params.data
        this.rules = {}
      } else {
        this.rules = {
          brand_name_cn: [
            { required: true, message: '请输入品牌名称(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          brand_name_us: [
            { required: true, message: '请输入品牌名称(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          route: [{ max: 60, message: '最大60个字符', trigger: 'blur' }],
          brand_logo_path: [{ required: true, message: '请上传Logo', trigger: 'blur' }],
          describe_cn: [{ min: 1, max: 1000, message: '长度在 1 到 1000 个字符', trigger: 'blur' }],
          describe_us: [{ min: 1, max: 1000, message: '长度在 1 到 1000 个字符', trigger: 'blur' }]
        }
      }
    },
    addInfoClick () {
      const coll = this.ruleForm.info.some(v => v.type === this.typeSelectValue)
      if (coll) return this.$message.warning('此类别已存在')
      this.ruleForm.info.push({
        type: this.typeSelectValue
      })
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.params.data ? await editBrand(this.ruleForm): await addBrand(this.ruleForm)
        if (result.success) {
          this.$message.success('修改成功')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">
.el-form {
  margin: 20px;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
